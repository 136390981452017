import React from 'react'

const AboutContent = () => {
  return (
    <>
      <div className="mx-4 md:mx-20 lg:mx-44 xl:mx-64 mt-40  grid lg:grid-cols-1  grid-cols-1  border-b h-fit py-10 lg:py-0 lg:h-[500px]">
        {" "}
        <div>
          <p className=" text-xl md:text-2xl lg:text-5xl font-semibold">
            ABOUT US
          </p>{" "}
          <div className="h-1 mt-5 w-72 bg-[#bb2649] rounded-md"></div>
      
          <div className="mt-10">
          Cabin Gate is a general construction and maintenance company that is based in Sulaymaniyah and has 
worked extensively throughout Iraq, having successfully completed several projects that have had a 
positive impact on the community. The company specializes in constructing various types of buildings, 
including residential homes, commercial properties, and industrial structures with mechanical and 
electrical setups. We also provide maintenance services for existing structures, ensuring they remain in 
good condition. The company's skilled team of engineers and builders work tirelessly to ensure that 
every project is completed on time and within budget. Their dedication to quality and customer 
satisfaction is evident in the high level of feedback received from clients. We demonstrate a strong 
commitment to safety and quality, which has helped them build a reputation for excellence in the 
industry. In summary, the construction and maintenance company has proven to be a valuable partner 
for their clients, providing reliable and efficient solutions to their building and maintenance needs.
          </div>
        </div>
        <div className=" ml-20    h-1/2 about-img bg-no-repeat lg:bg-contain lg:mt-20  hidden lg:flex"></div>
      </div>
    </>
  )
}

export default AboutContent