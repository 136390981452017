import React from 'react'
import AboutContent from '../component/AboutContent'
import Aboutheader from '../component/Aboutheader'
import Aboutsafe from '../component/aboutsafe'
import Aboutservice from '../component/aboutservice'

const About = () => {
  return (
    <div>
        <Aboutheader />
        <AboutContent />
        <Aboutservice />
        <Aboutsafe />
    </div>
  )
}

export default About