import React,{useContext} from 'react';
import logo from '../assets/image/cabingatelogo.png';
import '../css/menu.css';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { FaTwitter , FaFacebookF ,FaInstagram ,FaLinkedin } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { GlobalContext } from '../context/provider';
const Menu = () => {
    const location = useLocation();
    
    const {menuu,setmenuu}=useContext(GlobalContext);
  return (
    <div>
    <div className='h-full md:h-24 w-full hidden md:flex lg:flex xl:flex'>
        <div className='w-1/2 h-full flex justify-center'>
            <div className='w-48 h-full flex items-center justify-evenly'>
                <img src={logo} className="h-3/4 " /> 
            </div>
        </div> 
        <div className='w-1/2 h-full flex justify-center'>
            <div className='w-full h-full  flex items-center'>
                <div className={location.pathname=='/'?'h-12 w-24 relative flex justify-center items-center menuactive':'h-12 w-24 relative flex justify-center items-center'}>
                    <Link to='/'>Home</Link>
                </div>
                <div className={location.pathname=='/portflow'?'h-12 w-24 relative flex justify-center items-center menuactive':'h-12 w-24 relative flex justify-center items-center'}>
                    <Link to='/portflow'>Agencies</Link>
                </div>
                <div className={location.pathname=='/about'?'h-12 w-24 relative flex justify-center items-center menuactive':'h-12 w-24 relative flex justify-center items-center'}>
                    <Link to='/about'>About Us</Link>
                </div>
                <div className={location.pathname=='/contact'?'h-12 w-24 relative flex justify-center items-center menuactive':'h-12 w-24 relative flex justify-center items-center'}>
                    <Link to='/contact'>Contact Us</Link>
                </div>
                <div className={location.pathname=='/jointeam'?'h-12 w-32 relative flex justify-center items-center menuactive':'h-12 w-32 relative flex justify-center items-center'}>
                    <Link to='/jointeam'>Join Our team</Link>
                </div>
            </div>
        </div>
    </div>
    <div className={menuu?'bg-white top-16 right-0 left-[0%] bottom-0 md:hidden z-50 fixed transition-all duration-300':'bg-white top-16 right-0 left-[100%] bottom-0 md:hidden z-50 fixed transition-all duration-300'} >
        <div className='w-full h-56 flex justify-center items-center'>
            <img src={logo} className="h-3/4 " /> 
        </div>
        <div className='w-full h-auto'>
            <div className={location.pathname=='/'?'h-12 w-full flex items-center px-5 menuactivemob':'h-12 w-full flex items-center px-5'}>
                <Link to='/' onClick={()=>setmenuu(!menuu)}>Home</Link>
            </div>
            <div className={location.pathname=='/portflow'?'h-12 w-full flex items-center px-5 menuactivemob':'h-12 w-full flex items-center px-5'}>
                <Link to='/portflow' onClick={()=>setmenuu(!menuu)}>Agencies</Link>
            </div>
            <div className={location.pathname=='/about'?'h-12 w-full flex items-center px-5 menuactivemob':'h-12 w-full flex items-center px-5'}>
                <Link to='/about' onClick={()=>setmenuu(!menuu)}>About Us</Link>
            </div>
            <div className={location.pathname=='/contact'?'h-12 w-full flex items-center px-5 menuactivemob':'h-12 w-full flex items-center px-5'}>
                <Link to='/contact' onClick={()=>setmenuu(!menuu)}>Contact Us</Link>
            </div>
            <div className={location.pathname=='/jointeam'?'h-12 w-full flex items-center px-5 menuactivemob':'h-12 w-full flex items-center px-5'}>
                <Link to='/jointeam' onClick={()=>setmenuu(!menuu)}>Join Our team</Link>
            </div>
        </div>
        <div className={menuu?'w-full h-40 flex justify-center items-center':'hidden'}>
            <div className='w-48 h-full flex  items-center justify-evenly'>
                <a href="#"> <FaLinkedin color='#bb2649' size={22} /></a>
                <a href="#"> <FaFacebookF color='#bb2649' size={22} /></a>
                <a href="#"> <FaInstagram color='#bb2649' size={22} /></a>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Menu