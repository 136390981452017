import logo from './logo.svg';
import './App.css';
import Header from './component/header';
import Menu from './component/menu';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import Home from './page/home';
import Contact from './component/contact';
import Joinourteam from './page/joinourteam';
import Portfolio from './page/portfolio';
import About from './page/about';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Logggoa from './utils/whatslogo.jpg'

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Menu />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/portflow' element={<Portfolio />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/jointeam' element={<Joinourteam />} />
        </Routes>
        <FloatingWhatsApp phoneNumber="+9647744042424" accountName="Cabin Gate" avatar={Logggoa} />
      </div>
    </Router>
  );
}

export default App;
