import React from 'react'

const Aboutsafe = () => {
  return (
    <>
      <div className="mx-4 md:mx-20 lg:mx-44 xl:mx-64 mt-40 ">
        <div className="flex justify-center items-center gap-10  ">
          <div className="w-32 h-[1px] bg-[#bb2649]"></div>
          <p className="font-semibold  text-xl md:text-2xl lg:text-4xl">
            SAFETY
          </p>
          <div className="w-32 h-[1px] bg-[#bb2649]"></div>
        </div>
        <div className="mt-10 text-center  ">
          {" "}
          "Ensuring the safety of our customers and employees, as well as
          protecting the environment and workplace from potential hazards, is
          our top priority. At Cabin Gate, we take our responsibilities for
          health, safety, and environmental management seriously, in our office,
          on-site, and at client locations where we provide expertise. Our
          company has successfully obtained approval from “IOSH” for their safe
          management practices. Furthermore, “IOSH” is a professional institute
          for health and safety practitioners that sets standards and provides
          training and guidance on occupational safety and health. By obtaining
          “IOSH” approval, we have demonstrated our unwavering dedication to
          meeting and exceeding these high standards, and our commitment to
          continuous improvement.”
        </div>
        <div className="flex justify-center ">
            <div className="safty-img bg-cover bg-no-repeat w-72  mt-10 h-72 "></div>
        </div>
      </div>
    </>
  )
}

export default Aboutsafe