import React from 'react';
import Footer from './footer';
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { GrLocation } from "react-icons/gr";

const Contact = () => {
  return (
    <div>
      <div className='w-full min-h-[100vh] flex flex-wrap'>
        <div className='w-full md:w-1/2 min-h-[100vh] flex flex-col  md:mb-0'>
          <div className='mt-32 flex flex-col items-center'>
            <div className='text-black text-3xl font-bold'>Contact Us</div>
            <div className='text-gray-700 text-base my-4 font-bold'>Our friendly team would love to hear from you!</div>
          </div>
          <div className='mt-28 flex justify-center'>
            <div>
              <div className='flex px-4 py-2'><BiPhoneCall size={23}/> <p className='px-3'>+964 774 404 2424</p></div>
              <div className='flex px-4 py-2'><HiOutlineMail size={23}/><p className='px-3'> info@cabingate.com</p> </div>
              <div className='flex px-4 py-2'><GrLocation size={23}/><p className='px-3'> Iraq - Sulaymaniyah - Malik Mahmood Street - Mako Mall</p></div>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2 min-h-[100vh]'>
        {/* <iframe src= width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12985.153250218542!2d45.4594775!3d35.5465811!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40002befd034b5e5%3A0xa06179521a6b8e40!2zQ2FiaW4gR2F0ZSAtINmD2KfYqNmK2YYg2q_blduM2Ko!5e0!3m2!1sen!2siq!4v1695908149096!5m2!1sen!2siq" style={{width:'100%',height:'100%',border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact