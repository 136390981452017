import React from 'react'
import Bannerimage from '../component/bannerimage'
import Footer from '../component/footer'
import Latestproject from '../component/latestproject'
import Ourpartner from '../component/ourpartner'
import Ourservice from '../component/ourservice'
import Projects from '../component/projects'
import Slider from '../component/slider'
import Whatwedo from '../component/whatwedo'

const Home = () => {
  return (
    <div>
        <Slider />
        <Latestproject />
        <Projects />
        <Whatwedo />
        <Ourservice />
        <Bannerimage />
        <Ourpartner />
        <Footer />
    </div>
  )
}

export default Home