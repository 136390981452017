import React from 'react'
import img1 from '../assets/image/project1.jpg';
import img2 from '../assets/image/project2.jpg';
import img3 from '../assets/image/project3.jpg';
import img4 from '../assets/image/project4.jpg';
import img5 from '../assets/image/project5.jpg';
import img6 from '../assets/image/project6.jpg';
import img7 from '../assets/image/project7.jpg';
import img8 from '../assets/image/project8.jpg';
import '../css/project.css';
const Projects = () => {
  return (
    <div className='w-full h-auto flex flex-wrap'>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img1} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>SEMEIRAMIS</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img2} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>MONDI</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img3} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>SHORSH HOSPITAL</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img4} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>TOYOTA</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img5} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>HUAWEI</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img6} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>MONDI</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img7} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>TOYOTA</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 xl:w-1/4 h-80 relative projectcardhover'>
          <img src={img8} className="w-full h-full" />
          <div className='absolute top-0 left-0 right-0 bottom-[100%] bg-[#bb2649] opacity-80 hidden justify-center items-center text-white transition-all duration-500 projectcard'>MONDI</div>
        </div>
    </div>
  )
}

export default Projects