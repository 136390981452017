import React from 'react'
import img1 from '../assets/image/partner1.png';
import img2 from '../assets/image/partner2.png';
import img3 from '../assets/image/partner3.png';
import img4 from '../assets/image/partner4.png';
import img5 from '../assets/image/partner5.png';
import img6 from '../assets/image/partner6.png';
import img7 from '../assets/image/partner7.png';

const Ourpartner = () => {
  return (
    <div className='bg-stone-100'>
    <div className='w-full h-44 text-3xl font-bold flex justify-center pt-16 relative whatwedoactive'>Our Partner
    </div>
     <div className='flex items-center flex-col px-10'>
            <div>You can dream, create, design, and build the most wonderful place in the world. But it requires people to make the dream a reality</div>
            <div>– Walt Disney -</div>
      </div>
      <div className='py-10'>
        <div className='flex'>
          <div className="w-1/5 flex justify-center items-center"><img src={img1} className="w-2/4" /></div>
          <div className="w-1/5 flex justify-center items-center"><img src={img2} className="w-2/4"/></div>
          <div className="w-1/5 flex justify-center items-center"><img src={img5} className="w-2/4" /></div>
          <div className="w-1/5 flex justify-center items-center"><img src={img6} className="w-2/4"/></div>
          <div className="w-1/5 flex justify-center items-center"><img src={img7} className="w-2/4"/></div>
        </div>
      </div>
    </div>
  )
}

export default Ourpartner