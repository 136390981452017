import React from 'react'
import { IoIosConstruct } from "react-icons/io";
import { MdOutlineElectricalServices } from "react-icons/md";
import { GrVmMaintenance } from "react-icons/gr";
import { GiMechanicalArm } from "react-icons/gi";
import AOS from "aos";
import "aos/dist/aos.css";

const Ourservice = () => {
  AOS.init();
  return (
    <div className='bg-stone-100'>
    <div className='w-full h-44 text-3xl font-bold flex justify-center pt-16 relative whatwedoactive'>Our Services
    </div>
      <div className='flex items-center flex-col px-10'>
          <div>Endeavoring to provide the highest quality service to our customers</div>
          {/* <div>Buy INVEST - Construction PSD Template by eandf on ThemeForest.</div> */}
      </div>
      <div className='flex w-full justify-evenly py-24 flex-wrap'>
        <div className='w-full md:w-1/2 lg:w-1/4 flex flex-col items-center justify-between my-10 md:my-10 lg:my-0'>
          {/* <IoIosConstruct size={100} color="#bb2649" /> */}
          <i className="fa-sharp fa-solid fa-person-digging text-[#bb2649] text-[70px] py-4"  ></i>
          <div className='text-[#bb2649]'>General Construction Work</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 flex flex-col items-center justify-between my-10 md:my-10 lg:my-0'>
          <i class="fa-sharp fa-solid fa-plug text-[#bb2649] text-[70px] py-4"></i>
          <div className='text-[#bb2649]'>Electrical Work</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 flex flex-col items-center justify-between my-10 md:my-10 lg:my-0'>
          <i class="fa-sharp fa-solid fa-gear text-[#bb2649] text-[70px] py-4"></i>
          <div className='text-[#bb2649]'>Mechanical Work</div>
        </div>
        <div className='w-full md:w-1/2 lg:w-1/4 flex flex-col items-center justify-between my-10 md:my-10 lg:my-0'>
          <i class="fa-solid fa-list text-[#bb2649] text-[70px] py-4"></i>
          <div className='text-[#bb2649]'>General Maintenance</div>
        </div>
      </div>
    </div>
  )
}

export default Ourservice