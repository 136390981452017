import React,{useState} from 'react';
import logo from '../assets/image/cabingatelogo.png';
import logo1 from '../assets/image/cabingatelogo1.png';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';

const Footer = () => {
  const [name,setname]=useState();
  const [email,setemail]=useState();
  const [message,setmessage]=useState();

  const [loading,setloading]=useState(false);
  const send_email=()=>{
    setloading(true);
    if(name==''||!name||email==''||!email||message==''||!message){
      toast.error('Please Fill Information Correct !', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        setloading(false);
        return false;
    }
    emailjs.send('service_5oz3xio', 'template_lkfr1ub', {
      from_name:name,
      from_email:email,
      from_message:message
    },'cKb0vOT3nHvLJ8Cfx')
    .then(function(response) {
      toast.success('Your Message Sent , We Will Contact You Soon', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        setname('');
        setemail('');
        setmessage('');
        setloading(false)
    }, function(error) {
        setloading(false)
       console.log('FAILED...', error);
    });
  }
  return (
    <div className='w-full bg-[#bb2649] flex justify-around p-20 flex-wrap relative'>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
        <div className='w-full  md:w-1/3 lg:w-1/3 xl:w-1/3 flex flex-col'>
            <img src={logo1} className="w-20 h-20"/>
            <div className='w-full break-words py-5 text-white'>Thank you for considering our company for your construction and maintenance needs. We look forward 
to working with you.</div>
        </div>
        <div className='w-full  md:w-1/3 lg:w-1/3 xl:w-1/3 flex flex-col justify-center items-center text-white mt-12 md:mt-0'>
            <div>Contact Info</div>
            <div>If you get any question please do not hisitate to send us message ....</div>
            <div class="flex gap-6 mb-6 mt-7">
                <div>
                    <label for="first_name" class="block mb-2 text-sm font-medium text-white">Name</label>
                    <input type="text" id="first_name" class="bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="John" required value={name} onChange={(val)=>setname(val.target.value)} />
                </div>
                <div>
                    <label for="first_name" class="block mb-2 text-sm font-medium text-white ">Email</label>
                    <input type="text" id="first_name" class="bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Example@mail.com" required value={email} onChange={(val)=>setemail(val.target.value)} />
                </div>
            </div>
            <div class="mb-6 w-full">
                <label for="large-input" class="block mb-2 text-sm font-medium text-white">Message</label>
                <input type="text" id="large-input" placeholder='Write Your Message' class="block w-full p-4 text-black border border-gray-300 rounded-lg bg-white sm:text-md focus:ring-blue-500 focus:border-blue-500 " value={message} onChange={(val)=>setmessage(val.target.value)} />
            </div>
            {/* <div className='w-3/4 py-2'> */}
          <button onClick={()=>{
            send_email();
          }} class="bg-[#961a37] hover:bg-[#9e1b38] text-white font-bold py-2 px-4 border border-[#bb2649] rounded" disabled={loading?true:false}>
            SEND
          </button>
          {/* </div> */}
        </div>
        <div></div>
        <div className='absolute bottom-0 w-full h-12 bg-[#961a37] flex justify-center items-center text-white'>Copyright CabinGate @2023</div>
    </div>
  )
}

export default Footer