import React, { createContext, useReducer, useEffect, useState } from "react";
export const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {

    const [recaller, setrecaller] = useState(0);
    const [menuu, setmenuu] = useState(false);

    return <GlobalContext.Provider value={{
        menuu, setmenuu,
        recaller, setrecaller,
    }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider;