import React from 'react';
import '../css/latestproject.css';

const Latestproject = () => {
  return (
    <div className='w-full h-56 relative flex justify-center items-center bg-neutral-800'>
        <div className='text-white text-3xl latestprojectactive h-20'>
            LATEST PROJECTS
        </div>
        <div>

        </div>
    </div>
  )
}

export default Latestproject