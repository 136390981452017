import React,{useState} from 'react';
import img from '../assets/image/joinourteam.png';
import Footer from '../component/footer';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';

const Joinourteam = () => {
  const [name,setname]=useState();
  const [phone,setphone]=useState();
  const [city,setcity]=useState();
  const [hisjob,sethisjob]=useState();
  const [loading,setloading]=useState(false);

  const send=()=>{
    setloading(true);
    if(name==''||!name||phone==''||!phone||city==''||!city||hisjob==''||!hisjob){
      toast.error('Please Fill Information Correct !', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        setloading(false);
        return false;
    }
    emailjs.send('service_5oz3xio', 'template_5bbmztu', {
      from_name:name,
      from_phone:phone,
      from_city:city,
      from_whatyoucando:hisjob
    },'cKb0vOT3nHvLJ8Cfx')
    .then(function(response) {
      toast.success('Your Message Sent , We Will Contact You Soon', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        setname('');
        setphone('');
        setcity('');
        sethisjob('');
        setloading(false)
    }, function(error) {
        setloading(false)
       console.log('FAILED...', error);
    });
  }
  return (
    <div className='w-full h-[100vh] flex flex-wrap'>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
      <div className='w-full md:w-1/2 h-auto py-20 md:h-full md:py-0'>
        <div className='w-full h-40 flex flex-col justify-center items-center'>
          <div className='text-2xl font-bold'>
          Join Our Team
          </div>
          <div className='text-xl font-bold text-gray-600'>
          “Entrust your vision with us”
          </div>
        </div>
        <div className='w-full h-auto flex justify-center'>
          <img src={img} className="w-4/6" />
        </div>
      </div>
      <div className='w-full md:w-1/2 h-full'>
        <div className='w-full flex flex-col items-center py-36'>
          <div className='w-3/4 py-2'>
              <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Full name</label>
              <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="John Doe" required value={name} onChange={(val)=>setname(val.target.value)} />
          </div>
          <div className='w-3/4 py-2'>
              <label for="phone" class="block mb-2 text-sm font-medium text-gray-900">Phone</label>
              <input type="number" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="+964 770 123 4567" required value={phone} onChange={(val)=>setphone(val.target.value)} />
          </div>
          <div className='w-3/4 py-2'>
              <label for="city" class="block mb-2 text-sm font-medium text-gray-900">City</label>
              <input type="text" id="city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Sulaymaniyah" required value={city} onChange={(val)=>setcity(val.target.value)}/>
          </div>
          <div className='w-3/4 py-2'>
              <label for="large-input" class="block mb-2 text-sm font-medium text-gray-900">What you can do for us</label>
              <input type="text" id="large-input" class="block w-full p-6 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 " value={hisjob} onChange={(val)=>sethisjob(val.target.value)}/>
          </div>
          <div className='w-3/4 py-2'>
          <button class="bg-[#bb2649] hover:bg-[#cd2649] text-white font-bold py-2 px-4 border border-[#bb2649] rounded" disabled={loading?true:false} onClick={()=>{send();}}>
            SEND
          </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Joinourteam