import React from 'react'
import img1 from '../assets/image/partner3.png';
import img2 from '../assets/image/partner4.png';
const Portfolio = () => {
  return (
    <>
      <div className="mx-4 md:mx-20 lg:mx-44 xl:mx-64 mt-40 ">
        <div className="flex justify-center items-center gap-10  ">
          <div className="w-32 h-[1px] bg-[#bb2649]"></div>
          <p className="font-semibold  text-xl md:text-2xl lg:text-4xl">
            AGENCIES
          </p>
          <div className="w-32 h-[1px] bg-[#bb2649]"></div>
        </div>
        <div className="mt-10 text-center  ">
          {" "}
          At Cabin Gate, we strive to build and maintain long-term, mutually beneficial relationships with our 
          clients. Our one-step service center for water supply systems, firefighting pipes, and sewage system 
          allows our clients to take advantage of dealing with a single point of contact. We are authorized to sell 
          (ISIN Therm) that owned by EMS PLASTIC LTD and (Lux Wares) that owned by TORUN PLASTIK products 
          in Iraq, Sulaymaniyah.
        </div>
      </div>
      <div className='py-10 my-5'>
        <div className='flex'>
          <div className="w-1/2 flex justify-center items-center"><img src={img1} className="w-2/4" /></div>
          <div className="w-1/2 flex justify-center items-center"><img src={img2} className="w-2/4"/></div>
        </div>
      </div>
    </>
  )
}

export default Portfolio