import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Aboutservice = () => {
    AOS.init();
  return (
    <>
    <div className=" mx-4 md:mx-20 lg:mx-44 xl:mx-64  mt-40 pb-24 border-b" id="servicess">
      <div className=" text-xl md:text-2xl lg:text-5xl font-semibold ">
        OUR SERVICES
      </div>

      <div className="h-1 mt-5 w-96 bg-[#bb2649] rounded-md"></div>
      <div className="mt-10 ">
        Endeavoring to provide the highest quality service to our customers
      </div>
      <div className="mt-20 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-10  ">
        <div data-aos="fade-right" data-aos-duration="2000">
          <div className="h-44 bg-gray-50 rounded-lg flex items-center  gap-5 px-2   hover:scale-105  transition-all duration-700  hover:cursor-pointer  ">
            {" "}
            <div className="h-16 w-16   border rounded-full flex items-center justify-center bg-white">
              {" "}
              <i class="fa-sharp fa-solid fa-person-digging text-[#bb2649]"></i>
            </div>
            <div className=" font-bold  w-2/3 ">
              {" "}
              <h1 className="border-b-2 pb-1 border-[#bb2649] text-sm mb-1">
                General construction works
              </h1>
              <div className="text-xs mt-1 font-normal  grid grid-cols-2 ">
                <p>-Cabinetry</p>
                <p>-Decoration</p>
                <p>-flooring </p>
                <p>-roofing</p>
                <p>-concrete works </p>
                <p>-Paintings </p>
                <p>-Glass work </p>
                <p>-Steel work </p>
              </div>
            </div>
          </div>
        </div>
        {/* //////////////////// */}
        <div data-aos="fade-down" data-aos-duration="2000">
          <div className="h-44 bg-gray-50 rounded-lg flex items-center  gap-5  px-2 lg:px-8   hover:scale-105  transition-all duration-700  hover:cursor-pointer  ">
            {" "}
            <div className="h-16 w-16   border rounded-full flex items-center justify-center bg-white">
              {" "}
              <i class="fa-sharp fa-solid fa-plug text-[#bb2649]"></i>
            </div>
            <div className=" font-bold  w-2/3">
              {" "}
              <h1 className="border-b-2 pb-1 border-[#bb2649] text-sm mb-1">
                Electrical work
              </h1>
              <div className="text-xs mt-1 font-normal  grid grid-cols-2   ">
                <p>-Controlling board </p>
                <p>-installing and supplying </p>
                <p>-Security Camera </p>

                <p>-Tv & Satellite setup</p>
                <p>-Lighting</p>
                <p>-Ire alarm & Motion detector </p>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* ///////////// */}
        <div data-aos="fade-up" data-aos-duration="2000">
          <div className="h-44 bg-gray-50 rounded-lg flex items-center  gap-5  px-2 lg:px-8   hover:scale-105  transition-all duration-700  hover:cursor-pointer  ">
            {" "}
            <div className="h-16 w-16   border rounded-full flex items-center justify-center bg-white">
              {" "}
              <i class="fa-sharp fa-solid fa-gear text-[#bb2649]"></i>
            </div>
            <div className=" font-bold  w-2/3">
              {" "}
              <h1 className="border-b-2 pb-1 border-[#bb2649] text-sm mb-1">
                Mechanical work
              </h1>
              <div className="text-xs mt-1 font-normal  grid grid-cols-2   ">
                <p>-fire fighting </p>
                <p>-domestic water supply system </p>
                <p>-Water pumps station </p>

                <p>-drainage pipe system </p>
                <p>-pools </p>
                <p>-Cooling and heating system </p>
                <p>-Ventilation system </p>
              </div>
            </div>
          </div>
        </div>
        {/* //////////////// */}
        <div data-aos="fade-right" data-aos-duration="2000">
<div className="h-44 bg-gray-50 rounded-lg flex items-center  gap-5  px-8   hover:scale-105  transition-all duration-700  hover:cursor-pointer  ">
            {" "}
            <div className="h-16 w-16   border rounded-full flex items-center justify-center bg-white">
              {" "}
              <i class="fa-solid fa-list text-[#bb2649]"></i>
            </div>
            <div className=" font-bold  w-fit">
              {" "}
              <h1 className="border-b-2 pb-1 border-[#bb2649] text-sm mb-1">
                General maintenance
              </h1>
              <div className="text-xs mt-1 font-normal    ">
                <p>-Property management. </p>
                <p>-HVAC system maintenance</p>
                <p>
                  -General cleaning and sanitation of facilities and equipment{" "}
                </p>

                <p>-Roof and bathroom treatment</p>
              </div>
            </div>
          </div> 
        </div>
      </div>
      {/*  */}

      {/*  */}
    </div>
  </>
  )
}

export default Aboutservice