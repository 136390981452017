import React, { useContext } from 'react'
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { FaTwitter , FaFacebookF ,FaInstagram ,FaLinkedin } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { GlobalContext } from '../context/provider';

const Header = () => {
    const {menuu,setmenuu}=useContext(GlobalContext);
  return (
    <div className={menuu?'fixed w-full h-16 md:h-12 flex z-50':'w-full h-16 md:h-12 flex z-50'} style={{background:'#bb2649'}}>
        <div className='w-1/6 h-full flex px-5 items-center md:hidden' onClick={()=>{setmenuu(!menuu)}}>
            <HiMenu size={40} color="#fff" className='cursor-pointer' />
        </div>
        <div className='w-5/6 md:w-5/6 h-full flex pl-0 md:pl-16 justify-end md:justify-center'>
            <div className='w-48 h-full flex items-center justify-evenly'>
                <FiPhoneCall color='#fff' size={22} />
                <p className='text-sm text-white'>+964 7744042424</p>
            </div>
            <div className='w-48 h-full flex  items-center justify-evenly'>
                <HiOutlineMailOpen color='#fff' size={22} />
                <p className='text-sm text-white'>info@cabingate.com</p>
            </div>
        </div>
        <div className='w-1/2 h-full hidden md:flex lg:flex xl:flex justify-center'>
            <div className='w-48 h-full flex  items-center justify-evenly'>
                <a href="https://www.linkedin.com/company/cabin-gate-%D9%83%D8%A7%D8%A8%D9%8A%D9%86-%DA%AF%DB%95%DB%8C%D8%AA/"> <FaLinkedin color='#fff' size={22} /></a>
                <a href="https://www.facebook.com/cabingate"> <FaFacebookF color='#fff' size={22} /></a>
                <a href="https://www.instagram.com/cabingate"> <FaInstagram color='#fff' size={22} /></a>
            </div>
        </div>
    </div>
  )
}

export default Header