import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../css/slider.css';
import { Autoplay, Pagination, Navigation } from 'swiper';
import imgslider1 from '../assets/image/slider1.jpg';
import imgslider2 from '../assets/image/slider2.jpg';
import imgslider3 from '../assets/image/slider3.jpg';

const Slider = () => {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
  return (
    <div className='h-full w-full relative'>
        <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide><img src={imgslider1} className="sliderimgg md:opacity-60" /></SwiperSlide>
        <SwiperSlide><img src={imgslider2} className="sliderimgg md:opacity-60" /></SwiperSlide>
        <SwiperSlide><img src={imgslider3} className="sliderimgg md:opacity-60" /></SwiperSlide>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            {/* <circle cx="24" cy="24" r="20"></circle> */}
          </svg>
          <span className='hidden' ref={progressContent}></span>
        </div>
      </Swiper>
      <div className='absolute hidden md:flex md:w-1/3 md:h-60 md:top-1/4 md:left-32 z-50 text-[#bb2649] md:text-9xl'>
          Cabin Gate
      </div>
      <div className='absolute hidden md:w-2/3 lg:w-1/3 md:h-64 lg:h-60 md:top-2/4 md:left-32 z-50 text-[#bb2649] md:text-2xl lg:text-3xl md:flex md:items-center'>
      “Entrust your vision with us”
      </div>
    </div>
  )
}

export default Slider