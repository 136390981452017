import React from 'react';
import '../css/whatwedo.css';
import image1 from '../assets/image/whatwedo.jpg';
import image2 from '../assets/image/whatwedo1.jpg';
import image3 from '../assets/image/whatwedo2.jpg';
import image4 from '../assets/image/whatwedo3.jpg';
const Whatwedo = () => {
  return (
    <div>
        <div className='w-full h-44 text-3xl font-bold flex justify-center pt-16 relative whatwedoactive'>
            WHY WE ARE UNIQUE 
        </div>
        <div className='flex items-center flex-col px-10'>
            <div>we invest in advanced building materials that are eco-friendly, energy-efficient, and long-lasting to provide better-quality</div>
            <div> services while reducing the environmental impact of our work.</div>
        </div>
        <div class="flex justify-around mt-10 mb-16 flex-wrap">
          <div class="block max-w-xs rounded-lg bg-white shadow-lg mb-5">
            <a href="#!">
              <img
                class="rounded-t-lg h-1/2"
                src={image1}
                alt="" />
            </a>
            <div class="p-6">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800 ">
                Efficiency
              </h5>
              <p class="mb-4 text-base text-neutral-600 ">
              We take pride in our ability to deliver exceptional services to our clients, providing high-quality 
              workmanship in every project we undertake.
              </p>
            </div>
          </div>
          <div class="block max-w-xs rounded-lg bg-white shadow-lg mb-5">
            <a href="#!">
              <img
                class="rounded-t-lg h-1/2"
                src={image2}
                alt="" />
            </a>
            <div class="p-6">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800 ">
                Equipment
              </h5>
              <p class="mb-4 text-base text-neutral-600 ">
              Our team of experts has a wealth of experience in the field of construction and maintenance, enabling 
              us to save our customers time and money by using the latest technologies and tools available
              </p>
            </div>
          </div>
          <div class="block max-w-xs rounded-lg bg-white shadow-lg mb-5">
            <a href="#!">
              <img
                class="rounded-t-lg h-1/2"
                src={image3}
                alt="" />
            </a>
            <div class="p-6">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800 ">
                Duty
              </h5>
              <p class="mb-4 text-base text-neutral-600 ">
              We understand that quick responses to customer needs are crucial in our line of work. That is why we 
              provide trained work teams equipped with the latest technology, enabling us to respond promptly to our 
              customers' needs
              </p>
            </div>
          </div>
          <div class="block max-w-xs rounded-lg bg-white shadow-lg mb-5">
            <a href="#!">
              <img
                class="rounded-t-lg h-1/2"
                src={image4}
                alt="" />
            </a>
            <div class="p-6">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800 ">
                Welfare
              </h5>
              <p class="mb-4 text-base text-neutral-600 ">
              At our company, We pay attention to safety in all our projects, applying best practices and providing safe 
              solutions for our customers and workers. We have gained various safety certificates that attest to our 
              commitment to safety
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Whatwedo