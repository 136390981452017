import React from 'react'
import bannerimg from '../assets/image/banner.jpg';

const Bannerimage = () => {
  return (
    <div className='w-full relative'>
        <div className='absolute top-0 bottom-0 right-0 left-0 bg-stone-800 opacity-60' />
        <div className='absolute w-full h-full flex justify-center items-center text-white'>
        “Opening the Gate to exceptional constructions with precision and caution”
        </div>
        <img src={bannerimg} className="w-full h-96" />
    </div>
  )
}

export default Bannerimage