import React from 'react'

const Aboutheader = () => {
  return (
    <div>
        <div className="mx-4 md:mx-20 lg:mx-44 xl:mx-64 h-[300px]  lg:h-[400px] flex items-center justify-center  mt-32 bg-gray-50 relative    border-r-4 border-[#bb2649]  ">
      <div className="absolute top-0 right-0 w-32 h-1 bg-[#bb2649]"></div>
      <div className="absolute bottom-0 right-0 w-32 h-1 bg-[#bb2649]"></div>
      <div className="">
        <div className="flex items-center justify-center gap-2 text-center lg:gap-10">
          <hr className="lg:w-40 w-4   "></hr>
          <p className="flex items-center justify-center italic lg:text-base text-sm ">
          Entrust your vision with us
          </p>
          <hr className="lg:w-40 w-4   "></hr>
        </div>

        <h1
          data-aos="zoom-in"
          data-aos-duration="2000"
          className="flex justify-center text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold  mt-5"
        >
          ABOUT US
        </h1>

        <div className="flex items-center justify-center mt-10">
          <a className="flex items-center justify-center   border border-[#bb2649] hover:text-white hover:bg-[#bb2649] text-[#bb2649] bg-white px-5 text-xs lg:px-12 py-1  transition-all duration-500 rounded-sm font-bold lg:text-lg cursor-pointer" href='#servicess'>
            {" "}
            Our Service{" "}
          </a>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Aboutheader